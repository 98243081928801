<template>
  <v-container grid-list-md>
    <v-card style="margin-bottom:5em">
      <v-card-title>Student Enrollment Confirmation {{ selectedTermDesc }}</v-card-title>
      <v-card-text v-if="noTerms">
        <p>There are no terms available for confirmation, which typically opens up one week before classes begin and concludes several weeks into the term. If the term for which you wish to confirm has already started, please contact the Records office directly at <a href='mailto:recordsoffice@covenant.edu' target='_blank'>RecordsOffice@covenant.edu</a>.</p>
        <template v-if="upcomingTerms.length > 0">
          <p>These following terms will have their confirmation window open soon:</p>
          <ul style="margin-bottom:16px">
            <li v-for="(term, index) in upcomingTerms" :key="'upcomingTerm-' + index">{{ term }}</li>
          </ul>
        </template>
      </v-card-text>
      <template v-else-if="holds.length > 0">
        <v-card-text>According to our records, you currently have {{ holds.length }} hold{{ holds.length === 1 ? '' : 's' }} on your account. You must clear these holds before you can complete the confirmation checklist. Please contact the offices below about your holds or check your Banner account for more information.</v-card-text>
        <v-list>
          <v-list-item v-for="(hold, index) in holds" :key="'hold-'+index">
            <v-list-item-content>
              <v-list-item-title>{{ hold.code }} {{ hold.label }}</v-list-item-title>
              <v-list-item-subtitle>{{ hold.reason }}</v-list-item-subtitle>
              <v-list-item-subtitle>Date Added: {{ stringFormatDate(hold.dateAdded).split(' at ')[0] }}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </template>
      <v-card-text v-else-if="complete">
        <p>Thank you for verifying all of your information and confirming your enrollment!</p>
      </v-card-text>
      <v-card-text v-else-if="notRegistered">
        <p>According to our records, you are not registered for any term that is within the confirmation window. The terms within the window are:</p>
        <ul style="margin-bottom:16px">
          <li v-for="(desc, index) in termDesc" :key="'term-' + index">{{ desc }}</li>
        </ul>
        <p>If you believe should be registered for one of the above terms, please log into Banner to check your registration status or contact the Records office. If your registration was recently processed, then you may need to wait a few hours for your checklist to sync with Banner (the systems synchronize every few hours throughout the day).</p>
        <template v-if="upcomingTerms.length > 0">
          <p>The following terms will have their confirmation window open soon. So check back closer to the start of the term if your term is in this list:</p>
          <ul style="margin-bottom:16px">
            <li v-for="(term, index) in upcomingTerms" :key="'upcomingTerm-' + index">{{ term }}</li>
          </ul>
        </template>
        <p>If the term you are looking for is not in the above lists, then either the window has already passed or you need to wait a few weeks. The window starts the week before classes begin and ends on Friday of the week when classes begin.</p>
      </v-card-text>
      <template v-else-if="loaded">
        <v-card-text>
          <p>Please complete each item in the checklist below by clicking on the item and completing the form. The Enrollment Confirmation must be completed after everything else.</p>
        </v-card-text>
        <v-list>
          <v-list-item v-for="({ _id, title, detail, prereqs, complete }, index) in checklist" :key="'form-' + index" :to="complete ? '' : '/student/checklist/' + selectedTerm + '/' + title" :disabled="isDisabled(prereqs)">
            <v-list-item-avatar>
              <v-icon :color="complete ? 'success' : 'info'">{{ complete ? 'fas fa-check' : 'fas fa-exclamation-circle' }}</v-icon>
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title style="white-space:normal">{{ title }}</v-list-item-title>
              <v-list-item-subtitle style="white-space:normal">{{ detail }}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </template>
    </v-card>
  </v-container>
</template>
<script>
import { ref, computed, onMounted } from '@vue/composition-api'
import { stringFormatDate } from '../../../helpers/formatters'

export default {
  setup (props, { root, $route }) {
    const user = computed(() => 'spoof' in root.$store.state.user ? root.$store.state.user.spoof : root.$store.state.user)
    const checklist = ref([])

    const noTerms = ref(false)
    const termDesc = ref([])
    const selectedTerm = ref('')
    const selectedTermDesc = ref('')
    const upcomingTerms = ref([])

    const complete = ref(false)
    computed(() => {
      for (let l in checklist.value) {
        if (!checklist.value[l].submitted) return false
      }
      return true
    })

    const notRegistered = ref(false)
    const holds = ref([])
    const loaded = ref(false)

    onMounted(async () => {
      const { data: termData, total: termCount } = await root.$feathers.service('system/term').find({ query: { 'confirmation.start': { $lte: new Date() }, 'confirmation.end': { $gte: new Date() }, 'confirmation.checklist.0': { $exists: true } } })
      if (termCount === 0) {
        noTerms.value = true
      }
      const terms = []
      for (const { term, label } of termData) {
        terms.push(term)
        termDesc.value.push(label)
      }
      console.log(termData)
      const { total, data } = await root.$feathers.service('student/confirmation').find({ query: { pidm: user.value.pidm, term: { $in: terms } } })
      if (total === 0) {
        notRegistered.value = true
        return
      }
      const row = data[0]
      if ('holds' in row && Array.isArray(row.holds) && row.holds.length > 0) {
        holds.value = row.holds
      }
      for (let i = 0; i < termData.length; i++) {
        if (row.term === termData[i].term) {
          root.$store.commit('student/setTermId', termData[i]._id)
          selectedTerm.value = termData[i].term
          selectedTermDesc.value = ' for ' + termData[i].label
          let allComplete = true
          for (const { active, title, detail, classLevel, studentType, prereqs, form } of termData[i].confirmation.checklist) {
            if (!active) continue
            if (classLevel.length > 0) {
              // check to make sure the student is in the array of class levels
              let include = false
              for (const level in classLevel) {
                if (level === row.academic.classLevelCode) {
                  include = true
                }
              }
              if (!include) continue
            }
            if (studentType.length > 0) {
              // check to make sure the student is in the array of student types
              let include = false
              for (const type in studentType) {
                if (type === row.academic.studentType) {
                  include = true
                }
              }
              if (!include) continue
            }
            const temp = { title, detail, prereqs, form }
            // Check to see if the student has already completed this form
            if ('checklist' in row && Array.isArray(row.checklist) && row.checklist.length > 0) {
              for (const { title: itemTitle, submitted } of row.checklist) {
                if (itemTitle === title && submitted != null) {
                  temp.complete = true
                  break
                }
              }
            }
            if (!temp.complete) allComplete = false
            checklist.value.push(temp)
          }
          if (allComplete) complete.value = true
        }
      }
      root.$store.commit('student/setTerm', row.term)
      root.$store.commit('student/setConfirmationId', row._id)
      loaded.value = true

      let upcomingEnd = new Date()
      upcomingEnd.setDate(upcomingEnd.getDate() + 26)
      const { data: upcomingData } = await root.$feathers.service('system/term').find({ query: { 'confirmation.start': { $gt: new Date(), $lte: upcomingEnd } } })
      upcomingData.forEach(({ label }) => { upcomingTerms.value.push(label) })
    })

    function isDisabled (prereqs) {
      if (!prereqs || prereqs.length === 0) return false
      for (let i = 0; i < prereqs.length; i++) {
        let exists = false
        for (let j = 0; j < checklist.value.length; j++) {
          if (checklist.value[j].title === prereqs[i]) {
            if (!checklist.value[j].complete) {
              return true
            } else {
              exists = true
              break
            }
          }
        }
        if (!exists) return true
      }
      return false
    }

    return {
      user,
      noTerms,
      termDesc,
      selectedTerm,
      selectedTermDesc,
      upcomingTerms,
      notRegistered,
      holds,
      loaded,
      complete,
      checklist,
      isDisabled,
      stringFormatDate
    }
  }
}
</script>
